<template>
  <div class="report">
  <div class="group">
    <div class="info" v-for="(item, index) in plantReport" :key="index">
      <label>{{`Map ${item.map} total ref rw :`}}</label>
      <span>{{item && item.totalRewards}}</span>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'PlantRefReport',
  computed: {
    plantReport() {
      return this.$store.getters.plantReport
    }
  },
  created () {
    this.$store.dispatch('getPlantReport')
  }
}
</script>
<style lang="scss" scoped>
  .report {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    .group, .report-map {
      padding: 10px;
    }
    .info {
      display: flex;
    }
    .map-name {
      font-size: 24px;
    }
    label {
      min-width: 200px;
      font-size: 20px;
    }
    span {
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      text-align: right;
    }
  }
  </style>